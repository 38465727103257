import { useEffect, useMemo, useState } from "react";

type useMediaQueriesType = {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isTouch: boolean;
};

export default function useMediaQueries(): useMediaQueriesType {
  const [width, setWidth] = useState(0);

  const isTouch = useMemo(() => {
    if (typeof window === "undefined") return false;

    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  }, []);

  useEffect(() => {
    if (width === 0) {
      setWidth(window.innerWidth);
    }
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return {
    isDesktop: width > 1023,
    isTablet: width <= 1023 && width > 639,
    isMobile: width <= 639,
    isTouch,
  };
}
