import React, { useState } from "react";
import { Link } from "gatsby";

import { EmailOnlyFormProps } from "../../../shared/EmailOnlyForm/Email.type";
import { formInitialCtx } from "@contexts/FormOnlyEmail.context";
import { isValidEmail } from "@utils/validations";
import { IterableDataType } from "@types";
import { iterableSubmit } from "@utils/iterableSubmit";
import { mxpEmailCaptured } from "@utils/mixpanelEvents/mixpanelEvents";
import EmailOnlyFormProvider from "../../../shared/EmailOnlyFormProvider";
import generateUuId from "@utils/generateUuId";
import setRouteName from "@utils/getLocationName";

import styles from "./FooterEmailCapture.module.scss";
import { leadCapture } from "@utils/segment/leadCapture";

export type FooterEmailCaptureType = {
  headline: string;
  placeholder: string;
  buttonText: string;
  disclaimer: string;
};

const FooterEmailCapture: React.FC<FooterEmailCaptureType> = ({
  headline,
  placeholder,
  buttonText,
  disclaimer,
}) => {
  const [email, setEmail] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disclaimerMsg, setDisclaimerMsg] = useState<string>(disclaimer);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (email === "") throw new Error("emailIsRequired");

      if (!isValidEmail(email)) throw new Error("invalidEmail");

      window.analytics?.identify({
        account_status: "lead",
        email,
      });

      const analyticsData = {
        text: buttonText,
        email,
      };

      leadCapture(analyticsData);

      const data: IterableDataType = {
        email: email,
        SIGN_UP_SOURCE: "Website - Footer",
        eventName: "newUserSignup",
      };

      const { status } = await iterableSubmit(data);

      if (status === 200) {
        mxpEmailCaptured(email, buttonText);
        const user_id = generateUuId();

        window.dataLayer.push({
          event: "lead_capture",
          location: `footer-${setRouteName()}`,
          email,
          user_id,
        });

        setIsLoading(false);
        setEmail("");
        setIsError(false);
        return setDisclaimerMsg(
          "Thanks for signing up for SimplePractice emails!"
        );
      }
    } catch (error) {
      let errorMsg = {
        invalidEmail: "Your email is an invalid format.",
        emailIsRequired: "Your email is required.",
      } as const;

      let errorMsgKey: keyof typeof errorMsg | undefined;

      if (error instanceof Error) {
        errorMsgKey = error?.message as keyof typeof errorMsg;
      }

      setIsLoading(false);
      setIsError(true);
      return setDisclaimerMsg(errorMsg[errorMsgKey!] || "");
    }
  };

  const ctx: EmailOnlyFormProps = {
    ...formInitialCtx,
    buttonText,
    buttonStyle: "hollow-red",
    email,
    handleChange,
    handleSubmit,
    inputError: isError,
    isLoading,
    placeholder,
    spinnerColor: "red",
  };

  return (
    <div
      className={styles.emailCaptureSection}
      data-testid="email-capture-section"
    >
      <Link data-testid="headline" to="/" className={styles.headline}>
        {headline}
      </Link>

      <EmailOnlyFormProvider ctxInitialVal={ctx} withDropdown={false} />

      <p
        data-testid="disclaimer"
        className={`${styles.disclaimer} ${isError ? styles.errorMsg : ""}`}
      >
        {disclaimerMsg}
      </p>
    </div>
  );
};

export default FooterEmailCapture;
